<script setup lang="ts">
import { ref } from 'vue';
import { definePageMeta } from '#imports';
import BKIntegrationActionButtons from '~/features/bk/components/BKIntegrationActionButtons/BKIntegrationActionButtons.vue';
import BKIntegrationDemoBlock from '~/features/bk/components/BKIntegrationDemoBlock/BKIntegrationDemoBlock.vue';
import IFrameCaseOpenController from '~/features/bk/controllers/IFrameCaseOpenController/IFrameCaseOpenController.vue';
import BackgroundDropController from '~/controllers/BackgroundDropController/BackgroundDropController.vue';
import BKIntegrationLoader from '~/features/bk/components/BKIntegrationLoader/BKIntegrationLoader.vue';
import ItemChangeController from '~/features/bk/controllers/ItemChangeController/ItemChangeController.vue';
import IFrameSlider from '~/features/bk/components/IFrameSlider/IFrameSlider.vue';
import { useLocalIFrameStore } from '~/features/bk/store/localIFrame';
import { useDemoStore } from '~/features/bk/store/demo';
import BKIntegrationDemoPopup from '~/features/bk/components/BKIntegrationDemoPopup/BKIntegrationDemoPopup.vue';
import { ComponentNames, KEY_INJECT_INVENTORY_SERVICE } from '~/features/bk/constants/index.components';
import { useBkCasesStore } from '~/features/bk/store/bk.cases';
import { useBkModalStore } from '~/features/bk/store/bk.modal';
import BkInventoryService from '~/features/bk/services/BkInventoryService/BkInventoryService';
import { actionButtons } from '~/features/bk/controllers/IFrameController/IFrameController.data';
import { useBkIntegratorStore } from '~/features/bk/store/bk.integrator';
import { useBkInventoryStore } from '~/features/bk/store/bk.inventory';
import { useBkUserStore } from '~/features/bk/store/bk.user';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useBkErrorStore } from '~/features/bk/store/bk.error';

definePageMeta({
  layout: false,
});

// stores
const bkModalStore = useBkModalStore();
const integratorBk = useBkIntegratorStore();
const inventoryBk = useBkInventoryStore();
const userBk = useBkUserStore();
const store = useBkCasesStore();
const { isPending } = storeToRefs(store);

const localStore = useLocalIFrameStore();
const demoStore = useDemoStore();
const bkErrorStore = useBkErrorStore();

// refs stores
const { isModalOpened, modalComponent } = storeToRefs(bkModalStore);
const { popups } = storeToRefs(localStore);
const { caseBg } = storeToRefs(store);
const { urlOptions } = storeToRefs(integratorBk);
const { t, setLocale, loadLocaleMessages } = useI18n();

// init service
const bkInventoryService = new BkInventoryService(demoStore, inventoryBk);

const isShowCase = ref<boolean>(false);

// Подключаем ws соединение
onMounted(() => {
  inventoryBk.connectWsItemResponse();
  inventoryBk.connectWsStateChange();
});

// init data for case page
integratorBk.handlerUrl();
if (userBk) {
  await userBk.getClientId();
  if (urlOptions.value.languageIso) {
    await setLocale(urlOptions.value.languageIso);
    await loadLocaleMessages(urlOptions.value.languageIso.split('-')[0]);
  }
}

const nuxtApp = useNuxtApp();

nuxtApp.provide(KEY_INJECT_INVENTORY_SERVICE, bkInventoryService);

onMounted(async () => {
  if (userBk) await store.getCase(urlOptions.value.caseName);
  if (demoStore.isDemo) return;
  await inventoryBk.getNewInventory();
  await inventoryBk.getOldInventory();
});

watchEffect(() => {
  if (store.isError) {
    if (!userBk.clientId) {
      bkErrorStore.setBkModalErrorCode({
        key: ErrorCodes.AUTHENTICATION_EXCEPTION,
        msg: '',
      });
    } else {
      bkErrorStore.setBkModalErrorCode({
        key: ErrorCodes.CASE_NOT_FOUND,
        msg: '',
      });
    }
    bkModalStore.setCurrentComponent(ComponentNames.ERROR);
  }
});
</script>

<template>
  <div class="wrapper">
    <transition v-if="!isShowCase" name="fade">
      <BKIntegrationLoader v-model:loader="isShowCase" loader-position="relative" :is-pending-get-case="isPending" />
    </transition>
    <transition v-else name="fade">
      <BackgroundDropController :case-bg="caseBg" type="full" blur>
        <SharedModal v-model="isModalOpened">
          <component :is="modalComponent" />
        </SharedModal>
        <BKIntegrationDemoBlock v-if="demoStore.isDemo" />
        <IFrameSlider v-if="popups.gameInfo" v-model:show-slider="popups.gameInfo" />
        <!-- @vue-skip -->
        <ItemChangeController v-model="popups.change" />

        <IFrameCaseOpenController />
        <!-- @vue-skip -->
        <BKIntegrationDemoPopup v-model="popups.demoGetItems" />

        <BKIntegrationActionButtons :buttons="actionButtons(bkModalStore, t)" />
      </BackgroundDropController>
    </transition>
  </div>
</template>

<style src="./IFrameController.scss" scoped lang="scss"></style>
